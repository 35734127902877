<template>
  <a-row class="notifications-page">
    <a-form :form="form" @submit="handleSubmit">
      <a-col
        :span="24"
        style="background: #fff; padding: 30px 30px 0; min-height: 600px"
      >
        <a-alert
          class="mb-20"
          type="info"
          message="Utilize os campos abaixo para informar as permissões dos usuários do sistema:"
          banner
          style="font-weight: 500"
        />

        <a-tabs default-active-key="1" type="card">
          <a-tab-pane key="1" tab="GERAL">
            <a-collapse
              default-active-key="-1"
              class="collapse-modules"
              :bordered="false"
              expandIconPosition="right"
            >
              <a-collapse-panel
                v-for="(module, index) in settings.capabilities"
                :key="index"
              >
                <template slot="header">
                  Módulo:
                  <span class="module">{{ module.name }}</span>
                </template>
                <a-row>
                  <a-col :span="24">
                    <a-row v-for="(role, index) in module.roles" :key="index">
                      <a-checkbox-group
                        v-model="role.capabilitiesList"
                        @change="onChange"
                        style="width: 100%"
                      >
                        <a-col :span="24">
                          <a-row>
                            <a-col
                              class="name"
                              :class="
                                $root.windowWidth < 960 ? 'mb-10 mt-20' : ''
                              "
                              :span="$root.windowWidth > 960 ? 4 : 24"
                            >
                              {{ role.name }}
                            </a-col>
                            <a-col
                              v-for="(option, index) in module.options"
                              :key="index"
                              :span="$root.windowWidth > 960 ? 2 : 24"
                              ><a-checkbox
                                :value="option.value"
                                :disabled="role.slug == 'admin' ? false : false"
                              >
                                {{ option.label }}
                              </a-checkbox>
                            </a-col>
                          </a-row>
                        </a-col>
                      </a-checkbox-group>
                    </a-row>
                  </a-col>
                </a-row>
              </a-collapse-panel>
            </a-collapse>
          </a-tab-pane>
          <a-tab-pane key="2" tab="USUÁRIOS" force-render> ... </a-tab-pane>
        </a-tabs>

        <div class="a-right">
          <a-button
            type="primary"
            html-type="submit"
            :loading="loading"
            class="mt-30 mb-30"
          >
            SALVAR ALTERAÇÕES
          </a-button>
        </div>
      </a-col>
    </a-form>
  </a-row>
</template>

<script>
import roles from "@/json/roles.json";

export default {
  data() {
    let options = [
      { label: "Criar", value: "create" },
      { label: "Editar", value: "edit" },
      { label: "Ver", value: "view" },
      { label: "Listar", value: "list" },
      { label: "Deletar", value: "delete" },
    ];
    return {
      form: this.$form.createForm(this),
      roles,
      settings: {
        capabilities: [
          {
            module: "suppliers",
            name: "Fornecedores",
            options: options,
            roles: [
              {
                id: 1,
                name: "Administrador",
                slug: "admin",
                capabilitiesList: [],
              },
              {
                id: 2,
                name: "Financeiro",
                slug: "financial",
                capabilitiesList: [],
              },
              {
                id: 3,
                name: "Gestor",
                slug: "miles-manager",
                capabilitiesList: [],
              },
              {
                id: 4,
                name: "Gestor de Milhas",
                slug: "miles-manager",
                capabilitiesList: [],
              },
              {
                id: 5,
                name: "Consultor",
                slug: "user",
                capabilitiesList: [],
              },
            ],
          },
          {
            module: "contracts",
            name: "Contratos",
            options: [
              { label: "Criar", value: "create" },
              { label: "Editar", value: "edit" },
              { label: "Ver", value: "view" },
              { label: "Listar", value: "list" },
              { label: "Deletar", value: "delete" },
              { label: "Duplicar", value: "duplicate" },
              { label: "Cancelar", value: "cancel" },
              { label: "Gerar", value: "generate" },
              { label: "Logs", value: "logs" },
            ],
            roles: [
              {
                id: 1,
                name: "Administrador",
                slug: "admin",
                capabilitiesList: [],
              },
              {
                id: 2,
                name: "Financeiro",
                slug: "financial",
                capabilitiesList: [],
              },
              {
                id: 3,
                name: "Gestor",
                slug: "miles-manager",
                capabilitiesList: [],
              },
              {
                id: 4,
                name: "Gestor de Milhas",
                slug: "miles-manager",
                capabilitiesList: [],
              },
              {
                id: 5,
                name: "Consultor",
                slug: "user",
                capabilitiesList: [],
              },
            ],
          },
          {
            module: "miles-card",
            name: "Milhas",
            options: options,
            roles: [
              {
                id: 1,
                name: "Administrador",
                slug: "admin",
                capabilitiesList: [],
              },
              {
                id: 2,
                name: "Financeiro",
                slug: "financial",
                capabilitiesList: [],
              },
              {
                id: 3,
                name: "Gestor",
                slug: "miles-manager",
                capabilitiesList: [],
              },
              {
                id: 4,
                name: "Gestor de Milhas",
                slug: "miles-manager",
                capabilitiesList: [],
              },
              {
                id: 5,
                name: "Consultor",
                slug: "user",
                capabilitiesList: [],
              },
            ],
          },
          {
            module: "miles-orders",
            name: "Milhas: Pedidos",
            options: options,
            roles: [
              {
                id: 1,
                name: "Administrador",
                slug: "admin",
                capabilitiesList: [],
              },
              {
                id: 2,
                name: "Financeiro",
                slug: "financial",
                capabilitiesList: [],
              },
              {
                id: 3,
                name: "Gestor",
                slug: "miles-manager",
                capabilitiesList: [],
              },
              {
                id: 4,
                name: "Gestor de Milhas",
                slug: "miles-manager",
                capabilitiesList: [],
              },
              {
                id: 5,
                name: "Consultor",
                slug: "user",
                capabilitiesList: [],
              },
            ],
          },

          {
            module: "reports",
            name: "Relatórios",
            options: options,
            roles: [
              {
                id: 1,
                name: "Administrador",
                slug: "admin",
                capabilitiesList: [],
              },
              {
                id: 2,
                name: "Financeiro",
                slug: "financial",
                capabilitiesList: [],
              },
              {
                id: 3,
                name: "Gestor",
                slug: "miles-manager",
                capabilitiesList: [],
              },
              {
                id: 4,
                name: "Gestor de Milhas",
                slug: "miles-manager",
                capabilitiesList: [],
              },
              {
                id: 5,
                name: "Consultor",
                slug: "user",
                capabilitiesList: [],
              },
            ],
          },
        ],
      },
    };
  },
  beforeCreate() {
    this.$http
      .post("/option/get.php", {
        id: 11,
      })
      .then(({ data }) => {
        let dbCapabilities = JSON.parse(data.value).capabilities;
        this.settings.capabilities = dbCapabilities;
      })
      .catch(({ res }) => {
        res;
      });
  },
  methods: {
    handleSubmit(e) {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        console.log(err, values);
        if (!err) {
          this.loading = true;
          console.log("Received values of form: ", values);
          this.$http
            .post("/option/update", {
              id: 11,
              value: JSON.stringify(this.settings),
            })
            .then(() => {
              this.$message.success("Atualizado com sucesso!");
              this.loading = false;
            })
            .catch(() => {
              this.$message.error("Não foi possível atualizar.");
              this.loading = false;
            });
        } else {
          this.loading = false;
        }
      });
    },
  },
};
</script>

<style lang="sass" scoped>
.ant-alert-info
    font-size: 12px
.label
    font-size: 11px
    font-weight: 600
    color: #444
    display: block
    z-index: 1
.quillWrapper
    border: 1px solid #eee
.ant-radio-wrapper
    font-size: 13px
    font-weight: 500
</style>
